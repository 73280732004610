import { captureException } from '@sentry/browser';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { Store } from 'vuex';
import sliceNameMixin from '@core/mixins/slice-name.js';
import { Logger } from '@utils/logger';
import { StoreFactory } from '../store/store-factory';
import { RouterFactory } from '../views/router-factory.js';
import Root from './root.vue';

Vue.mixin(sliceNameMixin);

export function AppFactory(context) {
    Vue.config.warnHandler = (msg, vm, stack) => {
        Logger.warn({ err: { stack } }, msg);

        const exp = new Error(msg);
        exp.stack = stack;
        captureException(exp);
    };

    const router: VueRouter = RouterFactory(context);
    const store: Store<any> = StoreFactory(context);

    const app = new Vue({
        router,
        store,
        render: (h) => h(Root),
    });

    return {
        app,
        router,
        store,
    };
}
